import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    action: false,
    open: false,
    isMaintenance: false,
};

const load = createSlice({
    name: 'load',
    initialState,
    reducers: {
        showLoading(state, action) {
            const { open } = action.payload;
            state.action = !state.action;
            state.open = open;
        },

        hideLoading(state) {
            state.open = false;
        },

        showMaintenance(state) {
            state.isMaintenance = true;
        },

        hideMaintenance(state) {
            state.isMaintenance = false;
        },
    }
});

export default load.reducer;

export const { showLoading, hideLoading, showMaintenance, hideMaintenance } = load.actions;
